export const SHIFTS_SUMMARY_HEADER_TEXT = {
  SHIFTS_FOR_NEXT_30_DAYS: "Shifts for the next 30 days",
  SHIFTS_THAT_PAY: "Shifts that pay",
  SHIFTS_WITHIN: "Shifts within",
  NO_SHIFTS_FOR_NEXT_30_DAYS: "No shifts for the next 30 days",
  NO_SHIFTS_RIGHT_NOW: "No shifts right now",
  NO_SHIFTS_WITHIN: "No shifts within",
  SHIFTS_FOR: "Shifts for",
  LOADING_SHIFTS: "Loading Shifts for",
};
