import { Keyboard } from "@capacitor/keyboard";
import { isPlatform } from "@ionic/core";
import { IonInput, IonRow } from "@ionic/react";
import { KeyboardEvent, useEffect, useRef } from "react";

const CODE_LENGTH = 6;

interface OtpInputProps {
  verify: (code: string) => void;
  code: string;
  setCode: (code: string) => void;
  noBorder?: boolean;
}

export function OtpInputs(props: OtpInputProps) {
  const { verify, code, setCode, noBorder = false } = props;
  const inputRef = useRef<HTMLIonInputElement | null>(null);

  useEffect(() => {
    const timeout = setTimeout(focusInput, 100);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const focusInput = async () => {
    try {
      // This fails in the CI sometimes (flaky test), raised to team_sparta they will solve it.
      // when this get solved please delete this comment and try-catch
      await inputRef.current?.setFocus();
    } catch (error) {
      console.error(error);
    }
    if (isPlatform(window, "capacitor")) {
      Keyboard.show();
    }
  };

  const onCodeChange = (event: Event) => {
    let newOtpCode = event.target?.["value"] ? `${event.target?.["value"]}` : "";
    newOtpCode =
      newOtpCode?.trim().length > 6 ? newOtpCode.trim().substring(0, 6) : newOtpCode.trim();
    setCode(newOtpCode);
    if (newOtpCode?.length === CODE_LENGTH) {
      verify(newOtpCode);
      return;
    }
  };

  const onKeyUp = (event: KeyboardEvent<HTMLIonInputElement>) => {
    if (event.key === "Enter") {
      verify(code);
      return;
    }
  };

  //Need to monitor key press events to prevent entering more than 6 digits. This is needed as IonInput does not
  //validated max length for input type number.
  //Keypress event is used as it captures event only for characters. Not for keys like backspace, shift, delete etc.
  const onKeyPress = (event: KeyboardEvent<HTMLIonInputElement>) => {
    if (code.length === CODE_LENGTH) {
      event.preventDefault();
    }
  };

  return (
    <IonRow>
      <IonInput
        className={`otp-field-border ${noBorder ? "no-border" : ""}`}
        type="number"
        autocomplete="one-time-code"
        inputMode="numeric"
        ref={inputRef}
        onIonChange={onCodeChange}
        onKeyUp={onKeyUp}
        onKeyPress={onKeyPress}
        value={code}
        data-testid="otp-input"
        placeholder="123456"
      />
    </IonRow>
  );
}
