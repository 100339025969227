import { IonButton, IonImg, IonModal, IonText } from "@ionic/react";
import { logEvent } from "@src/appV2/lib/analytics";
import { memo, useEffect, useRef, useState } from "react";

import { IModal } from "./model.type";
import "./style.scss";

/*
isOpen is a "soft" on/off switch because opening and closing
the modal during a UI transition (opening/closing animation)
breaks the ionic modal and leaves the UI in a bad state.

to remedy this, the `useEffect` below allows the implementor
to pass `isOpen` regardless of the current state of the modal,
however we only update our internal state to do the actual
opening/closing once UI transitions are complete
*/

export const Modal = memo(
  ({
    isOpen,
    title,
    titleHtml,
    children,
    confirmText,
    onConfirm,
    onConfirmEvent,
    cancelText,
    onDismiss,
    onDismissEvent,
    onModalOpenEvent,
    onLayoutBodyContentScrollbarDetected,
    hideBunnyLogo,
    ...rest
  }: IModal) => {
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [modalTransitioning, setModalTransitioning] = useState<boolean>(false);
    const bodyRef = useRef<HTMLDivElement>(null);

    // log event on modal open, even if has already been opened
    useEffect(() => {
      if (modalOpen && onModalOpenEvent) {
        logEvent(onModalOpenEvent);
      }
    }, [modalOpen]); // eslint-disable-line react-hooks/exhaustive-deps

    // here's our soft on/off switch, triggered when isOpen or
    // modalTransitioning changes.
    // important: you can set the `isOpen` parameter, but
    // if the modal is in transition it will not open/close the modal
    // until the current transition is complete.
    useEffect(() => {
      if (modalTransitioning) {
        return;
      }
      setModalOpen(isOpen);
    }, [isOpen, modalTransitioning]);

    // sometimes, we'll want to keep content as small as possible so it
    // all fits within the viewport without scrolling. here, we tell
    // implementing clients about the scrollbar so they can adjust their content
    useEffect(() => {
      if (!bodyRef?.current) {
        return;
      }
      if (
        bodyRef?.current?.scrollHeight > bodyRef?.current?.clientHeight &&
        onLayoutBodyContentScrollbarDetected
      ) {
        onLayoutBodyContentScrollbarDetected();
      }
    }, [bodyRef?.current?.scrollHeight]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleOnConfirm = () => {
      if (onConfirmEvent) {
        logEvent(onConfirmEvent);
      }
      if (onConfirm) {
        onConfirm();
      }
    };
    const handleOnDismiss = () => {
      if (onDismissEvent) {
        logEvent(onDismissEvent);
      }
      if (onDismiss) {
        onDismiss();
      }
    };

    const handleModalTransition = (transitioning: boolean) => {
      // this can occassionally throw an error if a modal button
      // navigates the user away from this page, the modal is removed
      // from the DOM, and we try to set state.
      setModalTransitioning(transitioning);
    };

    if (!modalOpen) {
      return null;
    }

    return (
      <IonModal
        cssClass="modal-component"
        mode="ios"
        isOpen={modalOpen}
        onDidDismiss={() => handleModalTransition(false)}
        onDidPresent={() => handleModalTransition(false)}
        onWillDismiss={() => handleModalTransition(true)}
        onWillPresent={() => handleModalTransition(true)}
        {...rest}
      >
        <div className="modal-component-content">
          <div className="modal-component-header">
            {!hideBunnyLogo && (
              <div className="cbh-bunny-container">
                <IonImg src="/assets/logo/bunny.png" />
              </div>
            )}
            <IonText
              className={`ion-text-center ion-padding-horizontal ${
                hideBunnyLogo ? "ion-padding-top" : ""
              }`}
            >
              {title && <h1>{title}</h1>}
              {titleHtml}
            </IonText>
          </div>
          <div ref={bodyRef} className="modal-component-body">
            {children}
          </div>
          <div className="modal-component-footer ion-padding-top">
            {onDismiss && (
              <IonButton
                fill="clear"
                size="small"
                className="ion-text-wrap not-now-button"
                onClick={handleOnDismiss}
              >
                {cancelText || "Not now"}
              </IonButton>
            )}
            {onConfirm && (
              <IonButton
                size="large"
                className="ion-text-wrap modal-button"
                onClick={handleOnConfirm}
              >
                {confirmText || "Okay"}
              </IonButton>
            )}
          </div>
        </div>
      </IonModal>
    );
  }
);

Modal.displayName = "Modal";
