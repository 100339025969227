import { ExclusionForList } from "@src/app/api/exclusion";

type ExclusionState = {
  isLoading: boolean;
  exclusion?: ExclusionForList;
  errorMessage?: string;
};

type ExclusionAction =
  | {
      type: "exclusion/fetching";
    }
  | { type: "exclusion/failed"; data: { errorMessage: string } }
  | { type: "exclusion/success"; data: { exclusion: ExclusionForList } };

export const exclusionInitialState: ExclusionState = {
  isLoading: true,
};

export function exclusionReducer(_state: ExclusionState, action: ExclusionAction): ExclusionState {
  switch (action.type) {
    case "exclusion/fetching": {
      return { isLoading: true };
    }

    case "exclusion/failed": {
      return { isLoading: false, errorMessage: action.data.errorMessage };
    }

    case "exclusion/success": {
      return { isLoading: false, exclusion: action.data.exclusion };
    }
  }
}
